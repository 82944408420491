.sectionOne img,
.sectionTwo img,
.sectionThree img,
.sectionFour img {
  height: 100%;
  width: 100%;
}

.sectionOne {
  margin-top: 368px;
}

.sectionOne img {
  max-height: 496px;
}

.imgOne {
  margin-top: 360px;
}

.sectionTwo {
  margin-top: 224px;
}

.sectionThree {
  margin-top: 238px;
}

.sectionFour {
  margin-top: 152px;
}

.sectionFive {
  margin-top: 296px;
}

.sectionFive .title {
  font-family: 'oggbold';
  font-size: 60px;
  line-height: 1.2;
  text-align: center;
}

.sectionFive .title span {
  font-family: 'oggbolditalic';
}

.sectionFive .title .titleBold {
  font-family: 'ttcommonsbold';
}

.sectionSix {
  margin-top: 267px;
}
