.sectionOne img,
.sectionTwo img,
.sectionThree img,
.sectionFive img
 {
  height: 100%;
  width: 100%;
}

.sectionOne {
  margin-top: 376px;
}

.sectionThree {
  margin-top: 416px;
}

.sectionFive {
  margin-top: 384px;
}

.sectionTwo,
.sectionSix {
  margin-top: 152px;
}

.sectionFour {
  margin-top: 224px;
}

.sectionSeven {
  margin-top: 280px;
}

.sectionTwo .title {
  font-family: 'oggbold';
  font-size: 60px;
  line-height: 1.2;
  margin-bottom: 66px;
}

.sectionTwo .title span {
  font-family: 'oggbolditalic';
}

.imgTwo {
  max-height: 424px;
}
