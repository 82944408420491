.sectionOne img,
.sectionTwo img,
.sectionFour img {
  height: 100%;
  width: 100%;
}

.sectionOne {
  margin-top: 285px;
}

.sectionOne img {
  max-height: 496px;
}

.imgOne {
  margin-top: 216px;
}

.sectionTwo {
  margin-top: 369px;
}

.sectionThree {
  margin-top: 152px;
}

.sectionFour {
  margin-top: 200px;
}

.sectionThree .title {
  font-family: 'oggbold';
  font-size: 60px;
  line-height: 1.2;
  margin-bottom: 59px;
}

.sectionThree .title span {
  font-family: 'oggbolditalic';
}

.sectionFour img {
  max-height: 712px;
  max-width: 445px;
}

.sectionFive {
  margin-top: 288px;
}
