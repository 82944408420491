.sectionOne img,
.sectionTwo img,
.sectionThree img {
  height: 100%;
  width: 100%;
}

.sectionOne {
  margin-top: 336px;
}

.sectionTwo {
  margin-top: 288px;
}

.sectionThree {
  margin-top: 332px;
}

.sectionFour {
  margin-top: 369px;
}

.sectionFive {
  margin-top: 216px;
}

.certified {
  margin-top: 369px;
}

.green {
  color: #5b9c52;
  font-family: 'rocksalt';
  font-size: 28px;
  line-height: 1.93;
}

.partners {
  font-family: 'ttcommonsbold';
  font-size: 42px;
  font-weight: 600;
}

.gots {
  line-height: 1.71;
  font-family: 'oggbold';
}
