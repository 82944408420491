/* Column
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.columns {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

@media (min-width: 750px) {

  .one.columns {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .two.columns {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .three.columns {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .four.columns {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .five.columns {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .six.columns {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .seven.columns {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .eight.columns {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .nine.columns {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .ten.columns {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .eleven.columns {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .twelve.columns {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  /* Offsets */
  .offset-by-one.columns {
    margin-left: 8.333333%;
  }

  .offset-by-two.columns {
    margin-left: 16.666667%;
  }

  .offset-by-three.columns {
    margin-left: 25%;
  }

  .offset-by-four.columns {
    margin-left: 33.333333%;
  }

  .offset-by-five.columns {
    margin-left: 41.666667%;
  }

  .offset-by-six.columns {
    margin-left: 50%;
  }

  .offset-by-seven.columns {
    margin-left: 58.333333%;
  }

  .offset-by-eight.columns {
    margin-left: 66.666667%;
  }

  .offset-by-nine.columns {
    margin-left: 75%;
  }

  .offset-by-ten.columns {
    margin-left: 83.333333%;
  }

  .offset-by-eleven.columns {
    margin-left: 91.666667%;
  }
}
