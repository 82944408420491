/* Footer
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.footer {
  margin-top: 280px;
  padding-bottom: 36px;
}

.copyright,
.socialmedia {
  font-family: 'ttcommonsdemibold';
}

.copyright {
  font-size: 16px;
}

.socialmedia {
  font-size: 28px;
}

.socialmedia a {
  color: #000;
  margin-right: 50px;
  text-decoration: none;
}

