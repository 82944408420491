.text {
  font-family: 'oggbold';
  font-size: 60px;
  font-weight: 600;
  line-height: 1.2;
}

.email {
  font-size: 42px;
  font-weight: 600;
  line-height: 1.71;
}

.email a {
  color: #ff6a00;
  font-family: 'ttcommonsdemibolditalic';
}

.reachout {
  font-family: 'ttcommonsbold';
}

.typeface {
  font-family: 'zapfdingbats';
}
