html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'rocksalt';
  src: url('./fonts/RockSalt-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'ttcommonsbold';
  src: url('./fonts/TTCommons-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'ttcommonsdemibold';
  src: url('./fonts/TTCommons-DemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'ttcommonsmedium';
  src: url('./fonts/TTCommons-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'ttcommonsdemibolditalic';
  src: url('./fonts/TTCommons-DemiBoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'ttcommonsregular';
  src: url('./fonts/TTCommons-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'oggmedium';
  src: url('./fonts/Ogg-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'oggmediumitalic';
  src: url('./fonts/Ogg-MediumItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'zapfdingbats';
  src: url('./fonts/ZapfDingbatsITC.ttf') format('truetype');
}

@font-face {
  font-family: 'oggbold';
  src: url('./fonts/Ogg-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'oggbolditalic';
  src: url('./fonts/Ogg-BoldItalic.ttf') format('truetype');
}

/* Larger than mobile */
@media (min-width: 400px) {}

/* Larger than phablet (also point when grid becomes active) */
@media (min-width: 550px) {}

/* Larger than tablet */
@media (min-width: 750px) {}

/* Larger than desktop */
@media (min-width: 1000px) {}

/* Larger than Desktop HD */
@media (min-width: 1200px) {}

.devBorder {
  border: 1px dotted deeppink;
}
