/* Menu
–––––––––––––––––––––––––––––––––––––––––––––––––– */
.menuSticky {
  background-color: #fff;
  position: sticky;
  top: 0;
  z-index: 1030;
}

.menu {
  padding-top: 47px;
}

.logo {
  margin: 0;
}

.logo a {
  color: #010202;
  font-family: 'ttcommonsmedium';
  text-decoration: none;
}
