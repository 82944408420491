.sectionOne img,
.sectionTwo img,
.sectionFour img,
.sectionSix img,
.sectionSeven img,
.sectionEight img,
.sectionEleven img {
  height: 100%;
  width: 100%;
}

.sectionOne {
  position: relative;
}

.sectionTwo {
  margin-top: 152px;
}

.sectionThree {
  margin-top: 270px;
}

.sectionFour {
  margin-top: 296px;
}

.sectionFive {
  margin-top: 371px;
}

.sectionSix {
  margin-top: 212px;
}

.sectionSeven {
  margin-top: 224px;
}

.sectionEight {
  margin-top: 80px;
}

.sectionNine,
.sectionEleven {
  margin-top: 296px;
}

.sectionTen {
  margin-top: 20px;
}

.sectionTwelve {
  margin-top: 216px;
}

.sectionOne .textCopy {
  font-family: 'ttcommonsbold';
  font-size: 100px;
  letter-spacing: normal;
  line-height: 1.02;
  padding: 74px;
  position: absolute;
  text-align: center;
}

.sectionOne .textCopyItalic {
  font-family: 'oggmedium';
}

.sectionOne .textCopyItalic span {
  font-family: 'oggmediumitalic';
}

.sectionThree .text,
.sectionFive .text,
.sectionTen .text {
  font-family: 'ttcommonsmedium';
  font-size: 42px;
  line-height: 0.95;
}

.sectionFive .textmin,
.sectionTen .textmin {
  font-family: 'oggmedium';
  font-size: 34px;
  line-height: 1.18;
}

.sectionNine .textoggbold {
  font-family: 'oggbold';
  font-size: 34px;
}

.imgTwo {
  max-height: 320px;
}

.imgThree {
  margin-top: 38px;
}

.imgFour {
  max-height: 352px;
}

