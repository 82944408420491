.sectionOne img,
.sectionThree img,
.sectionFive img
 {
  height: 100%;
  width: 100%;
}

.sectionOne,
.sectionThree {
  margin-top: 164px;
}

.sectionTwo {
  margin-top: 225px;
}

.sectionThree {
  margin-top: 332px;
}

.sectionFour {
  margin-top: 152px;
}

.sectionFour .title,
.sectionSix .title {
  font-family: 'oggbold';
  font-size: 60px;
  line-height: 1.2;
  margin-bottom: 66px;
}

.sectionFour .title span,
.sectionSix .title span {
  font-family: 'oggbolditalic';
}

.sectionSix .title .titleBold {
  font-family: 'ttcommonsbold';
}

.sectionFive {
  margin-top: 180px;
}

.sectionSix {
  margin-top: 368px;
}

.imgTwo {
  max-height: 376px;
}

.copyBold {
  font-size: 28px;
  font-family: 'ttcommonsbold';
}

.copyNormal {
  font-family: 'ttcommonsregular';
  font-size: 34px;
  margin-bottom: 24px;
}

.copyNormal span {
  font-family: 'ttcommonsdemibold';
}
